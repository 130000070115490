import React, { Suspense, lazy, useEffect, useContext } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { StoreContext } from "GlobalData";
import { DEFAULT_CONFIG, SET_CONFIG } from "Constants";
import { LoadingDialog } from "Components/Dialogs";

import { CONFIG_LIBRARY } from "Constants";

const HomePage = lazy(() => import("Pages/Home"));
const ContactPage = lazy(() => import("Pages/Contact"));
const VechiclesPage = lazy(() => import("Pages/Vehicles"));
const LegalInformation = lazy(() => import("Pages/LegalInformation"));

const Routers = () => {
  const URL_HOST = window.location.host;
  const { state, setGlobalState } = useContext(StoreContext);

  useEffect(() => {
    let hostWithoutSuffix = URL_HOST.replace(".com", "");
    hostWithoutSuffix = hostWithoutSuffix.replace(".mx", "");
    let PAGE_DATA = CONFIG_LIBRARY?.[hostWithoutSuffix] || DEFAULT_CONFIG;
    setGlobalState(SET_CONFIG, PAGE_DATA);
    // eslint-disable-next-line
  }, []);

  if (!state.ready) {
    return <LoadingDialog open={true} />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingDialog open={true} />}>
        <Switch>
          <Route
            exact
            path={["/vehiculos/:vehicle", "/vehiculos/:vehicle/:model"]}
          >
            <VechiclesPage state={state} />
          </Route>
          <Route exact path="/contacto">
            <ContactPage state={state} />
          </Route>
          <Route exact path="/avisodeprivacidad">
            <LegalInformation state={state} />
          </Route>
          <Route path="/">
            <HomePage state={state} />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
