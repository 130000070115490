const ARKANA_TECHNO = {
  version: "Arkana Techno",
  image: "ARKANA/T.png",
  price: "480,000",
  features: [
    "Motor 1.3 L turbo de 4 cilindros",
    "Potencia de 140 hp",
    "Torque de 185 lb-ft",
    "Transmisión automática de doble embrague (EDC) de 7 velocidades",
    "Tanque de combustible de 50 L",
    "Largo: 4,568 mm",
    "Ancho: 1,820 mm",
    "Alto: 1,571 mm",
    "Espacio en cajuela: 410 L (ampliable a 938 L)",
    'Rines de aluminio bitono de 17"',
    'Pantalla táctil de 9.3" con replicación inalámbrica de Android Auto® y Apple CarPlay®',
    "Sistema Multi-Sense con modos de conducción personalizables",
    "Cargador inalámbrico",
    "Asientos delanteros calefactables",
    "Iluminación ambiental personalizable con 8 colores",
    "Detector de fatiga",
    "Sensores de proximidad laterales",
  ],
};

const ARKANA_ESPRIT_ALPINE = {
  version: "Arkana Esprit Alpine",
  image: "ARKANA/E.png",
  price: "530,000",
  features: [
    "Motor 1.3 L turbo de 4 cilindros",
    "Potencia de 140 hp",
    "Torque de 185 lb-ft",
    "Transmisión automática de doble embrague (EDC) de 7 velocidades",
    "Tanque de combustible de 50 L",
    "Largo: 4,568 mm",
    "Ancho: 1,820 mm",
    "Alto: 1,571 mm",
    "Espacio en cajuela: 410 L (ampliable a 938 L)",
    'Rines bitono de 18" con flancos personalizados con la firma Alpine',
    'Pantalla táctil de 9.3" con replicación inalámbrica de Android Auto® y Apple CarPlay®',
    "Sistema Multi-Sense con modos de conducción personalizables",
    "Cargador inalámbrico",
    "Volante calefactable con costuras en azul, blanco y rojo",
    "Asientos delanteros calefactables con tapicería en gamuza y piel sintética, decorados con el logo Alpine",
    "Iluminación ambiental personalizable con 8 colores",
    "Easy Park Assist (estacionamiento semiautónomo)",
    "Sensores de proximidad laterales",
    "Detector de fatiga",
  ],
};

const data = {
  group: "arkana",
  vehicle: "ARKANA",
  title: "ARKANA",
  versions: [ARKANA_TECHNO, ARKANA_ESPRIT_ALPINE],
  extras: [
    {
      title: "Motor y Rendimiento",
      image: "transmision.png",
      texts: [
        "Motor: 1.3 L turbo de 4 cilindros",
        "Potencia: 140 hp",
        "Torque: 185 lb-ft",
        "Transmisión: Automática de doble embrague (EDC)",
        "Número de velocidades: 7",
      ],
    },
    {
      title: "Consumo de Gasolina / Combustible",
      image: "gasolina.png",
      texts: ["Tipo: Gasolina", "Capacidad del tanque: 50 L"],
    },
    {
      title: "Dimensiones",
      image: "llanta.png",
      texts: [
        "Largo: 4,568 mm",
        "Ancho: 1,820 mm",
        "Alto: 1,571 mm",
        "Espacio en cajuela: 410 L (ampliable a 938 L)",
      ],
    },
    {
      title: "Tecnología y Conectividad",
      image: "pantalla.png",
      texts: [
        'Pantalla táctil de 9.3" con replicación inalámbrica de Android Auto® y Apple CarPlay®',
        "Sistema Multi-Sense con modos de conducción personalizables",
        "Cargador inalámbrico",
      ],
    },
    {
      title: "Confort y Comodidad",
      image: "sonido.png",
      texts: [
        "Volante calefactable con costuras en azul, blanco y rojo",
        "Asientos delanteros calefactables con tapicería en gamuza y piel sintética, decorados con el logo Alpine",
        "Iluminación ambiental personalizable con 8 colores",
      ],
    },
    {
      title: "Asistencias a la Conducción",
      image: "abs.png",
      texts: [
        "Easy Park Assist (estacionamiento semiautónomo)",
        "Sensores de proximidad laterales",
        "Detector de fatiga",
      ],
    },
  ],
};

export const ARKANA = {
  id: "arkana",
  title: "ARKANA",
  image: "ARKANA/T.png",
  to: "arkana",
  data,
};
