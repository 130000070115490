import { LV_HOST, LV_CONFIG } from "./RENAULT_LINDA_VISTA";
import { BARRAGAN_HOST, BARRAGAN_CONFIG } from "./RENAULT_BARRAGAN";
import { SALTILLO_HOST, SALTILLO_CONFIG } from "./RENAULT_SALTILLO";

export const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const PHONE_PATTERN = /(\d{2})[\s]?(\d{4})[\s]?(\d{4})/;
export const WHATSAPP_URL = "https://wa.me/";
export const FACEBOOK_URL = "https://www.facebook.com/";
export const INSTAGRAM_URL = "https://www.instagram.com/";
export const SET_CONFIG = "SET_CONFIG";
export const CURRENT_YEAR = "2025";

export const CONFIG_LIBRARY = {
  [LV_HOST]: LV_CONFIG,
  [BARRAGAN_HOST]: BARRAGAN_CONFIG,
  [SALTILLO_HOST]: SALTILLO_CONFIG,
};

const TEST_CONFIG = LV_HOST;
export const DEFAULT_CONFIG = CONFIG_LIBRARY[TEST_CONFIG];
