const EVOLUTION_MT = {
  version: "Evolution MT",
  price: "350,000",
  image: "KARDIAN/P.png",
  features: [
    "Motor de 4 cilindros",
    "Potencia de 113 hp",
    "Torque de 114 lb-ft @ 4000 rpm",
    "Transmisión manual de 5 velocidades",
    "Tanque de combustible de 50 L",
    'Rines flex bitono de 16"',
    "Largo: 4,119 mm",
    "Ancho: 1,773 mm",
    "Alto: 1,595 mm",
    "Espacio en cajuela: 410 L (ampliable a 938 L)",
  ],
};

const TECHNO_EDC = {
  version: "Techno EDC",
  price: "420,000",
  image: "KARDIAN/E.png",
  features: [
    "Motor de 3 cilindros",
    "Potencia de 115 hp",
    "Torque de 148 lb-ft @ 1750-3750 rpm",
    "Transmisión Efficient Dual Clutch (EDC) de 6 velocidades",
    "Tanque de combustible de 50 L",
    'Rines de aluminio bitono de 17"',
    "Largo: 4,119 mm",
    "Ancho: 1,773 mm",
    "Alto: 1,595 mm",
    "Espacio en cajuela: 410 L (ampliable a 938 L)",
  ],
};

const EVOLUTION_EDC = {
  version: "Evolution EDC",
  price: "400,000",
  image: "KARDIAN/P.png",
  features: [
    "Motor de 3 cilindros",
    "Potencia de 115 hp",
    "Torque de 148 lb-ft @ 1750-3750 rpm",
    "Transmisión Efficient Dual Clutch (EDC) de 6 velocidades",
    "Tanque de combustible de 50 L",
    'Rines flex bitono de 16"',
    "Largo: 4,119 mm",
    "Ancho: 1,773 mm",
    "Alto: 1,595 mm",
    "Espacio en cajuela: 410 L (ampliable a 938 L)",
  ],
};

const data = {
  group: "kardian",
  vehicle: "KARDIAN",
  title: "KARDIAN",
  versions: [EVOLUTION_EDC, TECHNO_EDC, EVOLUTION_MT],
  extras: [
    {
      title: "Motor y Rendimiento",
      image: "transmision.png",
      texts: [
        "Motor: 4 cilindros",
        "Potencia: 113 hp",
        "Torque: 114 lb-ft @ 4000 rpm",
        "Transmisión: Manual (MT)",
        "Número de velocidades: 5",
      ],
    },
    {
      title: "Consumo de Gasolina / Combustible",
      image: "gasolina.png",
      texts: ["Tipo: Gasolina", "Capacidad del tanque: 50 L"],
    },
    {
      title: "Dimensiones",
      image: "llanta.png",
      texts: [
        "Largo: 4,119 mm",
        "Ancho: 1,773 mm",
        "Alto: 1,595 mm",
        "Espacio en cajuela: 410 L (ampliable a 938 L)",
      ],
    },
    {
      title: "Rines y Llantas",
      image: "llanta.png",
      texts: ['Rines flex bitono de 16"'],
    },
  ],
};

export const KARDIAN = {
  id: "kardian",
  title: "KARDIAN",
  image: "KARDIAN/P.png",
  to: "kardian",
  data,
};
