import {
  KWID,
  LOGAN,
  STEPWAY,
  DUSTER,
  OROCH,
  KOLEOS,
  KANGOO,
} from "Constants/CARS";

const EMAILS = [
  "ana.favela@surman.com",
  "karina.hernandez@surman.com",
  "surman-renault-lindavista-adwords@leads.getsirena.com",
  "trodriguez@yeloubox.com",
  "omar.vargas@surman.com",
  "yeloubox.monterrey@gmail.com",
];

export const BARRAGAN_HOST = "renaultbarragan";

export const BARRAGAN_CONFIG = {
  host: BARRAGAN_HOST,
  emails: EMAILS,
  cars: [LOGAN, DUSTER, KOLEOS, KWID, OROCH, KANGOO, STEPWAY],
  banners: {
    version: "BARRAGAN",
    list: [
      { group: "", type: "jpg", image: "Banner web_Koleoss" },
      { group: "", type: "jpg", image: "Banner web_Oroch" },
    ],
  },
  schedules: [],
  social: [
    { type: "facebook", data: { url: "RenaultBarragan" } },
    { type: "instagram", data: { url: "renaultbarragan" } },
    {
      type: "whatsapp",
      data: { number: "8131432290", url: "https://wa.me/8131432290" },
    },
  ],
  services: [
    { title: "Prueba de manejo", image: "blk1.jpg", to: "/contacto" },
    {
      title: "Conoce nuestros modelos",
      image: "blk2.jpg",
      to: "/contacto?motivo=prueba",
    },
    {
      title: "Realiza tu",
      subtitle: "cita de servicio",
      renderTitle: true,
      image: "blk3.jpg",
      to: "/contacto?motivo=servicio",
    },
  ],
  contact: {
    text: "Para contactarnos por favor llena este sencillo formulario y no olvides explicar el motivo antes de hacer clic en el botón de Enviar. Pronto nos pondremos en contacto contigo para hacer un oportuno seguimiento de tu caso.",
    address:
      "Av. Manuel L. Barragán 100-interior A, Residencial Anahuac Nte, 66457 San Nicolás de los Garza, N.L., Mexico",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3593.718422511606!2d-100.31369612376709!3d25.746823709218283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866295a7ccd8863f%3A0x5f5542d5d3343f41!2sRenault%20Barrag%C3%A1n!5e0!3m2!1sen!2sus!4v1692723594735!5m2!1sen!2sus",
    options: [
      {
        type: "whatsapp",
        data: {
          text: "+52 8131432290",
          number: "8131432290",
          url: "https://wa.me/8131432290",
        },
      },
    ],
  },
  footer: {
    address:
      "Av. Manuel L. Barragán 100-interior A, Residencial Anahuac Nte, 66457 San Nicolás de los Garza, N.L., Mexico",
    legal: "Aviso de Privacidad",
  },
  terms:
    "Imágenes de uso ilustrativo. Pecios expresados en Pesos Mexicanos. Precios sujetos a cambios sin previo aviso. Vigencia Diciembre 2023",
};
