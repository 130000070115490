import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";

import "@fontsource/host-grotesk/400.css";
import "@fontsource/host-grotesk/700.css";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Host Grotesk, Arial, sans-serif !important",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*, *::before, *::after": {
          boxSizing: "content-box",
        },
        body: {
          backgroundColor: "#FFF",
        },
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
        marginBottom: "10px",
      },
    },
  },
});

export default theme;
